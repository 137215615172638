@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/*booking*/
.form {
  background-color: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.form-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.form-icon {
  width: 100px;
  height: 100px;
}

.form-heading {
  font-size: 45px;
  color: #0c0c0d;
  vertical-align: middle;
  /* margin-left: 40px; */
  text-align: center;
  font-weight: 700;
  margin: 30px 0;
  font-weight: bold;
}

.form-content {
  padding: 20px;
  background-color: #d5d5d4;
  border-radius: 25px;
  width: 70%;
  height: 100%;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.form-control {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-submit {
  background-color: #706464;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .form-content {
    width: 90%;
  }

  #booking .form-heading {
    font-size: 23px;
    text-align: center;
  }
}

/*end of booking */
/*faq*/
.faq-header {
  color: #0c0c0d;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 100px;
}

.accordion {
  display: flex;
  flex-direction: column;
  color: #0c0c0d;
  max-width: 1400px;
  min-width: 320px;
  margin: 50px auto;
  padding: 0 100px;
}

.accordion-item {
  margin-top: 10px;
  border: 1px solid #fcfcfc;
  border-radius: 6px;
  background: #d5d5d4;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.accordion-item .accordion-item-title {
  position: relative;
  margin: 0;
  display: flex;
  width: 100%;
  font-size: 23px;
  font-weight: 500;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 14px 20px;
  box-sizing: border-box;
  align-items: center;
}

.accordion-item .accordion-item-desc {
  display: none;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  color: #0c0c0d;
  border-top: 1px dashed #ddd;
  padding: 10px 20px 20px;
  box-sizing: border-box;
}

.accordion-item input[type="checkbox"] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.accordion-item input[type="checkbox"]:checked~.accordion-item-title {
  background-color: #706464;
  color: white;
  border-radius: 6px;
}

.accordion-item input[type="checkbox"]:checked~.accordion-item-desc {
  display: block;
}

.accordion-item input[type="checkbox"]~.accordion-item-title .icon:after {
  content: "▼";
  font-size: 20px;
  transform: rotate(90deg);
  transition: transform 0.5s ease;
}

.accordion-item input[type="checkbox"]:checked~.accordion-item-title .icon:after {
  content: "▲";
  font-size: 20px;
  transform: rotate(0deg);
}

.accordion-item:first-child {
  margin-top: 0;
}

.accordion-item .icon {
  margin-left: 14px;
}

@media screen and (max-width: 768px) {
  .accordion {
    padding: 0 20px;
  }

  .accordion-item .accordion-item-title {
    font-size: 18px;
    /* Decrease font size for small screens */
  }

  .accordion-item .accordion-item-desc {
    font-size: 16px;
    /* Decrease font size for small screens */
  }
}

/* end faq*/

.facebook-button,
.instagram-button {
  border-radius: 20px;
  margin-right: 10px;
}


.facebook-button {
  background-color: #3b5998;
  color: white;
  padding: 5px 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.facebook-button:hover {
  background-color: #2d4373;
}

.instagram-button {
  background: linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7);
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.instagram-button:hover {
  filter: brightness(1.1);
}

/*end social*/
/*review*/
video {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100vh;
}

.video-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  /* Center horizontally */
  text-align: center;
  /* Center content inside */
}

.video-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  margin: 20px;
}

.video-container iframe {
  width: 600px;
  /* Adjust the width as needed */
  height: 400px;
  /* Adjust the height as needed */
  margin: 10px;
  /* Adjust the margin as needed */
}

@media screen and (max-width: 768px) {
  .video-container {
    grid-template-columns: 1fr;
  }

  .video-container iframe {
    width: calc(100% - 20px);
    /* Set iframe width to 100% on smaller screens */
  }

  #video h1 {
    font-size: 23px;
  }

  #video h2 {
    font-size: 23px;
  }
}

.testimonial {
  min-height: 375px;
  position: relative;
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: center;
  background-size: cover;
}

#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #000;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #000;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #000;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #000;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}

.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}

.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}

.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #000;
  border: 2px solid #000;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}

.testimonial4_header h4 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}

.testimonial4_slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 136px;
  height: 136px;
  margin: auto;
  display: block;
  color: #000;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}

.testimonial4_slide p {
  color: black;
  font-size: 20px;
  line-height: 1.4;
  margin: 40px 0 20px 0;
}

.testimonial4_slide h4 {
  color: black;
  font-size: 22px;
}

.testimonial .carousel {
  padding-bottom: 50px;
}

.testimonial .carousel-control-next-icon,
.testimonial .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
  background-color: #000;
}

@media (max-width: 769px) {
  .testimonial {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .testimonial h2 {
    font-size: 18px;
  }

  .testimonial4_header h4 {
    font-size: 18px;
  }

  .testimonial4_slide p {
    font-size: 16px;
    margin: 20px 0 10px 0;
  }

  .testimonial4_slide h4 {
    font-size: 18px;
  }

  .testimonial4_slide img {
    width: 100px;
    height: 100px;
  }
}

/*end of review*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/*nav bar*/
.bg-brown {
  background-color: #fefffe;
  font-weight: bold;
}

.bg-gray {
  background-color: #fefffe;
  transition: background-color 0.3s ease;
  color: #0c0c0d;
  font-weight: bold;
}

.navbar {
  position: fixed;
  z-index: 22;
  width: 100%;
  top: 0;
}

.navbar-nav .nav-link {
  color: #0c0c0d !important;
  margin: 0 10px;
}

.navbar-toggler {
  color: #0c0c0d !important;
  border-color: #0c0c0d !important;
}

.navbar-toggler-icon {
  color: #0c0c0d !important;
}

.navbar-nav .nav-item:hover .nav-link {
  color: #0c0c0d !important;
}

.btn-white12 {
  color: #0c0c0d !important;
  background-color: transparent !important;
  border-color: #706464 !important;
  margin-left: 20px !important;
  border-radius: 25px !important;
  font-weight: bold;
}

.btn-white12:hover {
  color: #ffffff !important;
  background-color: #706464 !important;
  font-weight: bold;
}

/* Footer */
.footer {
  background: url(./images/footer.png) center center no-repeat;
  background-size: contain;
  background-color: #706464;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border: 1px solid rgba(256, 256, 256, 0.1);
  border-radius: 40px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: #706464;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  transition: 0.3s;
  text-decoration: none;
}

/* .footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  /* font-family: "Font Awesome 5 Free"; 
  font-weight: 900;
  margin-right: 10px;
} */

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

@media (max-width: 769px) {
  .footer {
    text-align: left;
    /* Align footer content to the left */
  }
}

/* end Footer */
/*process*/
.container2 {
  width: 100%;
  text-align: center;
}

.progressbar {
  counter-reset: step;
  margin-bottom: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
}

.progressbar li {
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
}

/* .progressbar li span {
  display: block;
  position: absolute;
  top: 40px; /* Adjust the top position to align the text under the circle
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Make the text span the full width
  margin-top: 5px; /* Adjust margin for spacing
} */

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 100px 10px auto;
  background-color: #fff;
  transition: background-color 0.3s ease;
  /* Added transition for background color */
}

.progressbar li.active:before {
  background-color: #706464;
  /* Filled color for active step */
}

.section {
  display: none;
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  /* Hide overflow to allow height transition */
  transition: height 0.3s ease;
  /* Added transition for height */
}

.section.active {
  display: block;
  height: auto;
  /* Set height to auto when section is active */
}

.section h2 {
  margin-bottom: 10px;
}

.section p {
  margin-bottom: 20px;
}

.section img {
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto 20px auto;
}

@media screen and (max-width: 768px) {

  .progressbar li {
    width: 50px;
    margin-bottom: 30px;
  }
}

/*end of process*/
/*privacy*/
#privacy p {
  margin-left: 50px;
}

#privacy h2 {
  margin-left: 50px;
}

@media (max-width: 768px) {
  #privacy p {
    margin-left: 25px;
  }

  #privacy h2 {
    margin-left: 25px;
    font-size: 23px;
  }
}

/*end of privacy*/
.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  background: #7f7f7f;
  color: white;
  height: 30px;
}

.swiper-button-next:hover,
.swiper-rtl .swiper-button-prev:hover,
.swiper-button-prev:hover,
.swiper-rtl .swiper-button-next:hover {
  background-color: #000;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 25px;
  font-weight: 900;
}

.message {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: #706464;
}

.message {
  display: flex;
  justify-content: center;
  perspective: 500;
  padding-top: 25vh;
}

.texts {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
  color: #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 20vh;
  /* Decreased font size for smaller view */
  line-height: normal;
  cursor: wait;
  height: 50vh;
  width: 90%;
  outline: 10px;
  outline-style: double;
  text-align: center;
}

.texts:hover {
  transform: rotateY(180deg);
}

.text {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.text-front {
  z-index: 1;
}

.text-back {
  transform: rotateY(180deg);
}

@media screen and (max-width: 768px) {
  .texts {
    font-size: 14vh;
    /* Further decrease font size for smaller screens */
  }
}

@media only screen and (max-width: 988px) {
  .navbar-collapse {
    display: unset !important;
  }

  .collapse:not(.show) {
    display: none !important;
  }

}

#reviews {
  margin-top: 100px;
}

#client h2,
#reviews .headingrev {
  font-size: 45px;
  font-weight: 700;
  margin: 30px 0;
  text-align: center;
}

/**************sign in********************/
.signin {
  text-align: center;
  min-height: 70vh;
}

.signin h2 {
  font-size: 46px;
  font-weight: 700;
  color: #706464;
}

.signin .row {
  align-items: center;
}

.signin .formDiv {
  padding: 10px;
  margin-top: -30px;
}

.signin .formDiv input {
  padding: 15px 10px;
  font-size: 16px;
  border: 1px solid #706464;
  margin-top: 40px;

}

.signin img {
  width: 100%;
  object-fit: contain;
}

.signin div {
  margin-top: 20px;
}

.signin button {
  background-color: #706464;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
  font-size: 22px;
}

@media only screen and (max-width: 900px) {
  .formDiv {
    margin-top: 80px !important;
  }
}


.form-control:focus {
  box-shadow: none;
}

/************************tablePage******************/
.tablePage {
  padding: 100px;
}

.tablePage table {
  text-align: center;
}

.tablePage h2 {
  font-size: 45px;
  margin: 30px 0;
  text-align: center;
  color: #706464;
  font-weight: 700;
}

.tableres {
  width: 100%;
  overflow-x: scroll;
}

.tablePage table thead th {
  background-color: #706464;
  color: #fff;
  font-size: 20px;
}

@media screen and (max-width: 900px) {
  .tablePage {
    padding: 100px 10px;
  }

  .tablePage h2 {
    font-size: 22px;
  }

  .tablePage table thead th {

    font-size: 14px;
  }

  .tableres {
    width: 90%;


  }
}